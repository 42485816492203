body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: garamond, serif !important;
}

.section1,
.section2,
.section3,
.section4,
.section5 {
  max-width: unset !important;
}

.brightSection {
  background-color: #f7f7f7;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  max-width: none !important;

  &.brightDetailSection {
    min-height: 70vh;
  }
}

.brightSectionHolder {
  background-color: #f7f7f7;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  max-width: none !important;
}

.section1 {
  // background-image: url('../public/images/image1.jpeg');
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-family: garamond, serif !important;

  &.navlinksPopUp {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    align-items: start;
    margin-left: 30%;

    .navlinkdetailspopup {
      font-size: 22px;
      margin-bottom: 30px;
      line-height: 30px;
      color: #f2f2f2;
      font-weight: 500;
      font-family: garamond, serif;
      text-transform: none;
    }
  }
}
.menu {
  position: absolute;
  font-size: 14vw;
  letter-spacing: 3vw;
  font-weight: 500;
  margin: auto;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.2;
  text-shadow: 9px 17px 25px rgba(0, 0, 0, 0.12);
}

.popupLinks {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &.address {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .emailContent {
    margin-left: 30%;
    //width: 500px;

    .email {
      font-size: 17px;
      line-height: 16px;
      color: #f2f2f2;
      margin-bottom: 40px;
    }
  }
}

.text {
  font-weight: 300;
  color: #666;
}

.requestButton {
  width: 100%;
  height: 55px;
  color: #fff !important;
  fill: transparent !important;
  background-color: rgba(31, 31, 31, 0.99);
  border: 1px solid #fff !important;
  text-transform: uppercase;
  font-size: 17px !important;
  font-weight: 500 !important;
  letter-spacing: 2px !important;
  border-radius: 0 !important;
  cursor: pointer;
  font-family: garamond, serif !important;

  &.discoverButton {
    margin-top: 25px;
    width: 30%;
    background-color: #f7f7f7;
    border: 1px solid #000;
    color: #000;
    font-size: 15px;
    height: 50px;
  }
}
.no-scroll {
  overflow: hidden;
}

.scroll {
  overflow-y: scroll;
}

.css-it3ls0-MuiToolbar-root {
  padding: 0px 40px !important;
}

.css-1d3asr0-MuiContainer-root {
  padding: 0px !important;
}

.css-1accv8u-MuiButtonBase-root-MuiButton-root {
  font-family: garamond, serif !important;
}

.container__slider {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;
  padding: 0 !important;
}

.container__slider > button {
  background-color: transparent;
  outline: none;
  border: 0;
  color: whitesmoke;
  font-size: 24px;
}

.container__slider > button:active {
  color: black;
}

.slider__btn-next {
  position: absolute;
  right: 0;
}

.slider__btn-prev {
  position: absolute;
  left: 0;
}

.container__slider__links {
  width: 45%;
  display: flex;
  flex-direction: row;
  bottom: 20px;
  justify-content: space-around;
  right: 55px;
}

.container__slider__links-small {
  position: relative;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  outline: none;
  border: 0;
  background-color: white;
  border: 1px solid grey;
  transition: background-color 1s ease;
  margin: 0 2px;
}

.container__slider__links-small-active {
  background-color: black;
}

.slider__item {
  box-sizing: border-box;
  min-width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  height: auto;
  transform: translateX(0);
  transition: transform 2 ease;
}

.slider__item-active-1 {
  transform: translateX(0);
}

.slider__item-active-2 {
  transform: translateX(-100%);
}

.slider__item-active-3 {
  transform: translateX(-200%);
}

.slider__item-active-4 {
  transform: translateX(-300%);
}

.slider__item-active-5 {
  transform: translateX(-300%);
}

.slider__item-active-6 {
  transform: translateX(-300%);
}

.slider__item-active-7 {
  transform: translateX(-300%);
}

.slider__item img {
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover;
}

.container__slider {
  position: relative;
}

.slider__item {
  position: relative;
  display: none;
}

.slider__item-active {
  display: block;
}

.slider__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  text-align: start;
}
.content {
  width: 80%;

  .sliderTitle {
    max-width: 60%;
    font: 65px / 1.2 garamond, serif;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .slider__link {
    color: white;
    text-decoration: underline;
    font-family: garamond, serif;
    font-size: 17px;
    text-decoration: none;
  }
}

.container__slider__link-item {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.slider__count {
  margin-top: 10px;
}

.about {
  width: 50%;
  padding: 10px 10px 10px 0px;
  font: 200 17px / 26px garamond, serif;
  color: #878787;
}

.categories {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .category {
    height: 100vh;
    width: 33.333333333333333%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    transition: all 0.5s ease;
    position: relative;
    filter: brightness(0.3);
    // border: 1px solid rgb(242, 242, 242);
    margin: auto;
    .content {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 37px;
      font-weight: 600;
      flex-direction: column;
      line-height: 40px;
      letter-spacing: 2px;
      text-align: center;
    }
  }
}

.category:hover {
  width: 100%;
  filter: brightness(1); /* Restore brightness on hover */
}

.active {
  color: #000;
}

.css-91k8o2-MuiContainer-root {
  height: 100vh !important;
}

.css-1mfov8a-MuiContainer-root {
  max-width: unset !important;
}
.css-zwo7a,
.css-1accv8u-MuiButtonBase-root-MuiButton-root,
.css-1apkmlw-MuiButtonBase-root-MuiButton-root,
.css-1qsg32w-MuiButtonBase-root-MuiButton-root {
  font-family: garamond, serif !important;
}

button {
  box-shadow: none !important;
  outline: none !important;
}
.aboutDetails {
  padding: 10px !important;
}

.briefTitle {
  padding: 0px 0px 0px 10px !important;
}

.fixedImage {
  position: fixed; /* Fix the position */
  top: 0;
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Height of the section */
  background-image: url('../public/images/image4.jpeg');
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the background image */
  z-index: -1; /* Make sure it stays behind other sections */
  margin: auto;
}

.profileSection {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &.horizontal {
    background-image: url('../public/images/image5.png');
    height: 50vh;
    margin-bottom: 30vh;
    margin-top: 30vh;
  }

  &.vertical {
    background-image: url('../public/images/image6.png');
    height: 90vh;
    margin-bottom: 10vh;
    margin-top: 10vh;
  }
}

@media screen and (max-width: 1300px) {
  .nav-links {
    width: 60% !important;
  }
}

@media screen and (max-width: 930px) {
  .imgSection {
    flex-direction: column !important;
    height: unset !important;

    .imageHolder {
      width: 100% !important;
      padding: 25px 20px !important;
    }
    .contentHolder {
      width: 100% !important;
      padding: 25px 20px !important;
    }
  }

  .contentDetails {
    width: 87% !important;
  }

  .aboutDetails {
    width: unset !important;
  }
  .aboutDetailsHolder {
    width: 73% !important;
  }
}

@media screen and (max-width: 840px) {
  .titleHolder {
    width: 100% !important;
  }
  .aboutSection {
    flex-direction: column !important;
  }

  .about {
    width: 100% !important;
    margin-left: 0 !important;
    text-align: justify !important;
  }
  .detailSection {
    min-height: unset !important;
  }

  .brightSection {
    min-height: 100vh !important;

    &.brightDetailSection {
      min-height: 80vh !important;
    }
  }

  .word {
    margin-top: -550 !important;
  }

  .wordContact {
    margin-top: -840px !important;
  }

  .contactHolder {
    flex-direction: column !important;
    .holder {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
  .content .sliderTitle {
    font: 40px/1.2 garamond, serif !important;
  }

  .briefTitle {
    width: 80.5vw !important;
  }

  .address {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 680px) {
  .word {
    display: none !important;
  }

  .wordContact {
    display: none !important;
  }

  .categories {
    flex-direction: column !important;
    .category {
      width: 100% !important;
      height: 33.33% !important ;

      .content {
        height: 100% !important;
      }
    }
  }
  .detailSection {
    min-height: unset !important;
  }

  .brightSection {
    min-height: 100vh !important;
  }

  .brightSectionContact {
    min-height: 120vh !important;
  }

  .brightSection.brightDetailSection {
    min-height: 90vh !important;
  }

  .footer {
    flex-direction: column !important;

    .footerSection {
      width: 100% !important;
      flex-direction: column !important;
      align-items: start !important;
    }
  }

  .footerLogo {
    //width: 68vw !important;
  }
  .content .sliderTitle {
    font: 40px/1.2 garamond, serif !important;
  }

  .linksHolder {
    justify-content: start !important;
  }
  .container__slider__links {
    width: 100% !important;
  }

  .contentDetails {
    width: 100% !important;
  }

  .aboutDetailsHolder {
    width: 99% !important;
    padding: 25px 20px !important;
  }
  .briefTitle {
    width: 93.5vw !important;
  }

  .footer {
    padding: 0 !important;
  }

  footer {
    margin: unset !important;
    padding: 20px 0px !important;
  }

  .categories .category {
    filter: brightness(0.95) !important;
  }
}

@media screen and (max-width: 640px) {
  .menuContainer {
    .popupLinks {
      width: 100% !important;
      align-items: center;
      .nav-links.navlinksPopUp {
        margin-left: 0 !important;
        align-items: center !important;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .holderGridFilter {
    margin: 20px 20px !important;
  }

  .contactHolder {
    .emailContent {
      width: 80% !important;
    }
    .inputHolder {
      width: 80% !important;
    }
  }
}

@media screen and (max-width: 420px) {
  .titleHolder {
    width: 100% !important;
    font-size: 23px !important;
    margin-bottom: 15px !important;
    line-height: 38px !important;
  }
  .aboutSection {
    flex-direction: column !important;
  }

  .about {
    width: 100% !important;
    margin-left: 0 !important;
    line-height: 25px;
    font-size: 14px !important;
  }

  .detailSection {
    min-height: unset !important;
  }

  .brightDetailSection {
    min-height: 100vh !important;
  }

  .brightSection {
    min-height: 100vh !important;
  }
  .detailSection {
    min-height: unset !important;
  }

  .brightSectionContact {
    min-height: 140vh !important;
    &.brightDetailSection {
      min-height: 100vh !important;
    }
    &.contactSection {
      min-height: 120vh !important;
    }
  }

  .detailTitle {
    font-size: 24px !important;
    margin-bottom: 15px !important;
  }

  .categories .category .content {
    font-size: 25px;
  }

  .text {
    font-size: 14px !important;
  }
  .emailContent {
    .email {
      font-size: 17px;
      line-height: 16px;
      color: #f2f2f2;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 380px) {
  .brightSectionContact {
    min-height: 140vh !important;
    &.brightDetailSection {
      min-height: 100vh !important;
    }
  }
}

@media screen and (max-width: 325px) {
  .holderGridFilter {
    margin: 20px 5px !important;
  }

  .brightSectionContact {
    min-height: 145vh !important;
  }
}

@media screen and (max-width: 285px) {
  .brightSectionContact {
    min-height: 130vh !important;
  }
}

.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0; /* Start from the left */
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.1s; /* Transition for fade effect */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide.active {
  opacity: 1; /* Fully visible when active */
}

.carousel-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-cover {
  position: relative;
  z-index: 10;
  color: white;
  padding: 30px;
  height: 100%;
  display: flex;
  align-items: center;
}

.header-content {
  text-align: center;
  position: relative;
}

/* Floating line animation */
.floating-line {
  background-color: white;
  margin: 20px auto;
  transition: all 1.5s ease-in-out;
  transform-origin: center center;
}

.line-animation-0 {
  width: 40%;
  border-radius: 50%;
  height: 50px;
  height: 3px;
  transform: rotate(180deg);
}

.line-animation-1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  animation: spin 2s infinite linear;
}

.line-animation-2 {
  width: 100%;
  height: 3px;
  transform: rotate(180deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animated-letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s, transform 0.3s;
}

.animate {
  opacity: 1;
  transform: translateY(0);
}

@keyframes flyIn {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Start slightly lower */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
  }
}

/* Additional styles for better visuals */
.carousel-slide {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Smooth transition for fade effect */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide.active {
  left: 0;
  opacity: 1; /* Make the active slide visible */
}

.carousel-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-title {
  font-weight: 500;
  opacity: 1 !important;
  color: #fff; /* Highlight color for the active title */
}

.animated-border-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.animated-border-box {
  width: 70vw;
  height: 60vh;
  position: relative;
  margin: auto;
}

.animated-border-box::before {
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 2px solid white;
  box-sizing: border-box;
  animation: draw-border 4s ease-in-out forwards;
}

.css-12t8d7m-MuiContainer-root {
  height: 100vh !important;
}

.css-1b7hv0u {
  max-width: unset !important;
}

@keyframes draw-border {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
  }
  25% {
    width: 50%;
    height: 0;
    border-color: #fff;
  }
  50% {
    width: 0%;
    height: 100%;
    border-color: #fff;
  }
  75% {
    width: 0;
    height: 100%;
    left: 100%;
    border-color: #fff;
  }
  100% {
    width: 100%; /* Draw left side to complete the rectangle */
    height: 100%;
    border-color: transparent; /* Fade out border */
  }
}

// for images
.image-container {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative; /* Important for overlay positioning */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1s ease; /* Smooth scaling transition */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray background */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
  text-align: center;
}

.overlayMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent gray background */
  display: flex;
  justify-content: start;
  align-items: end;
  color: white;
  font-size: 17px;
  font-weight: 600;
  transition: opacity 0.3s ease;
}

/* Show overlay on hover */
.image-container:hover .overlay {
  opacity: 1; /* Show overlay when hovered */
}

.image-container:hover .overlayMobile {
  display: none !important;
}

.requestButton:hover {
  background-color: #000 !important;
}
